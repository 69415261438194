import { useCallback, useRef, useState } from 'react';

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const toKebabCase = str => {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
};


export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
