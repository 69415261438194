/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

/**
 * Returns the address object ID of the user if it exists.
 *
 * @param {Object} user - The user object.
 * @returns {string|undefined} - The address object ID of the user if it exists, otherwise undefined.
 */
export const getAddressObjectId = user => {
  return user?.attributes?.profile?.publicData?.addressObjectId;
};

/**
 * Returns the Shippo address of the given user.
 *
 * @param {object} user - The user object to extract the address from.
 * @returns {string|null} The Shippo address of the user or null if it doesn't exist.
 */
export const getShippoAddress = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.shippoAddress;
};

export const getDefaultDimensions = user => {
  return user?.attributes?.profile?.publicData?.defaultDimensions;
};

/**
 * Returns the pay in total amount of a transaction.
 * @param {Object} tx - The transaction object to retrieve the pay in total from.
 * @returns {number|undefined} The pay in total amount of the transaction, or undefined if it cannot be retrieved.
 */
export const getPayInTotal = tx => {
  return tx && tx.id && tx?.attributes?.payinTotal?.amount;
};


export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};