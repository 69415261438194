// ================ Action types ================ //

import { createAddress, fetchCarriers } from '../util/api';

export const CREATE_ADDRESS_REQUEST = 'app/shippo/CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'app/shippo/CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_ERROR = 'app/shippo/CREATE_ADDRESS_ERROR';

export const FETCH_CARRIERS_REQUEST = 'app/shippo/FETCH_CARRIERS_REQUEST';
export const FETCH_CARRIERS_SUCCESS = 'app/shippo/FETCH_CARRIERS_SUCCESS';
export const FETCH_CARRIERS_ERROR = 'app/shippo/FETCH_CARRIERS_ERROR';

export const FETCH_IS_INTERNATIONAL_SHIPPING = 'app/shippo/FETCH_IS_INTERNATIONAL_SHIPPING';

// ================ Reducer ================ //

const initialState = {
  createAddressInProgress: false,
  shippoAddressCreated: false,
  createAddressError: null,
  carriers: [],
  carriersError: null,
  isInternational: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ADDRESS_REQUEST:
      return { ...initialState, createAddressInProgress: true };

    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        createAddressInProgress: false,
        shippoAddressCreated: true,
      };
    case CREATE_ADDRESS_ERROR:
      return {
        ...state,
        createAddressInProgress: false,
        shippoAddressCreated: false,
        createAddressError: true,
      };
    case FETCH_IS_INTERNATIONAL_SHIPPING:
      return { ...state, isInternational: payload?.isInternational };
    case FETCH_CARRIERS_REQUEST:
      return { ...state, fetchCarriersInProgress: true };
    case FETCH_CARRIERS_SUCCESS:
      return { ...state, fetchCarriersInProgress: false, carriers: payload || [] };
    case FETCH_CARRIERS_ERROR:
      return { ...state, fetchCarriersInProgress: false, carriersError: true };

    default:
      return state;
  }
}

// ================ Selector ================ //

export const selectCreateAddressInProgress = state => state.shippo.createAddressInProgress;
export const selectShippoAddressCreated = state => state.shippo.shippoAddressCreated;
export const selectCreateAddressError = state => state.shippo.createAddressError;

export const selectSearchCarriers = state => state.shippo.carriers;

// ================ Action creators ================ //

export const createAddressRequest = () => ({
  type: CREATE_ADDRESS_REQUEST,
});
export const createAddresSuccess = () => ({
  type: CREATE_ADDRESS_SUCCESS,
});
export const createAddressError = () => ({
  type: CREATE_ADDRESS_ERROR,
});

export const fetchCarriersRequest = () => ({
  type: FETCH_CARRIERS_REQUEST,
});
export const fetchCarriersSuccess = payload => ({
  type: FETCH_CARRIERS_SUCCESS,
  payload,
});
export const fetchCarriersError = () => ({
  type: FETCH_CARRIERS_ERROR,
});

export const fetchIsInternationalShipping = isInternational => ({
  type: FETCH_IS_INTERNATIONAL_SHIPPING,
  payload: { isInternational },
});

// ================ Thunks ================ //

export const createShippoAddress = params => async dispatch => {
  dispatch(createAddressRequest());
  try {
    const address = await createAddress(params);
    address && dispatch(createAddresSuccess());
    return address;
  } catch (error) {
    dispatch(createAddressError());
  }
};

export const fetchShippingCarriers = params => async (dispatch, getState, sdk) => {
  dispatch(fetchCarriersRequest());
  try {
    const response = await fetchCarriers(params);
    dispatch(fetchCarriersSuccess(response?.carriers));
    dispatch(fetchIsInternationalShipping(response?.isInternational));
    return response;
  } catch (error) {
    dispatch(fetchCarriersError());
  }
};
