import React from 'react';

const ICON_STROKE_COLOR = '#2A353D';

const icons = {
  contact: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="2"
        width="17.5"
        height="20"
        rx="4"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <path
        d="M10.59 13.7408C9.96125 14.162 8.31261 15.0221 9.31674 16.0983C9.80725 16.624 10.3536 17 11.0404 17H14.9596C15.6464 17 16.1928 16.624 16.6833 16.0983C17.6874 15.0221 16.0388 14.162 15.41 13.7408C13.9355 12.7531 12.0645 12.7531 10.59 13.7408Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <path
        d="M15 9C15 10.1046 14.1046 11 13 11C11.8954 11 11 10.1046 11 9C11 7.89543 11.8954 7 13 7C14.1046 7 15 7.89543 15 9Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <path
        d="M5 6L2.5 6M5 12L2.5 12M5 18H2.5"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  password: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9982 2.00009C8.99043 2.00009 7.04018 4.01908 4.73371 4.75499C3.79589 5.05422 3.32697 5.20383 3.1372 5.41474C2.94743 5.62565 2.89186 5.93384 2.78072 6.55023C1.59143 13.1461 4.1909 19.2441 10.3903 21.6176C11.0564 21.8726 11.3894 22.0001 12.0015 22.0001C12.6135 22.0001 12.9466 21.8726 13.6126 21.6176C19.8116 19.244 22.4086 13.1461 21.219 6.55022C21.1078 5.93373 21.0522 5.62549 20.8624 5.41458C20.6726 5.20367 20.2037 5.05414 19.2659 4.75508C16.9585 4.01924 15.0061 2.00009 11.9982 2.00009Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.00009L12 9.00009"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  card: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C22 6.69377 22 8.46252 22 12C22 15.5375 22 17.3062 20.9472 18.4871C20.7788 18.676 20.5932 18.8506 20.3925 19.0091C19.1379 20 17.2586 20 13.5 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16H11.5"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 16L18 16"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 9H22" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  ),
  paypal: (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M5.50971 2.90194C5.55646 2.66823 5.76166 2.5 6 2.5H14.2602C16.4993 2.5 18.1625 4.57346 17.6768 6.75926L17.0101 9.75926C16.6543 11.3606 15.2339 12.5 13.5935 12.5H9.89039L8.48508 18.1213C8.42943 18.3439 8.22944 18.5 8 18.5H3C2.85021 18.5 2.7083 18.4328 2.61333 18.317C2.51836 18.2012 2.48034 18.0488 2.50971 17.9019L5.50971 2.90194ZM6.40991 3.5L3.60991 17.5H7.60962L9.01493 11.8787C9.07058 11.6561 9.27057 11.5 9.5 11.5H13.5935C14.7652 11.5 15.7798 10.6862 16.034 9.54233L16.7006 6.54233C17.0476 4.98104 15.8595 3.5 14.2602 3.5H6.40991Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.2602 6.5H17V5.5H17.2602C19.4993 5.5 21.1625 7.57346 20.6768 9.75926L20.0101 12.7593C19.6543 14.3606 18.2339 15.5 16.5935 15.5H12.8904L11.4851 21.1213C11.4294 21.3439 11.2294 21.5 11 21.5H6C5.85021 21.5 5.7083 21.4328 5.61333 21.317C5.51836 21.2012 5.48034 21.0488 5.50971 20.9019L6.10971 17.9019L7.09029 18.0981L6.60991 20.5H10.6096L12.0149 14.8787C12.0706 14.6561 12.2706 14.5 12.5 14.5H16.5935C17.7652 14.5 18.7798 13.6862 19.034 12.5423L19.7006 9.54233C20.0476 7.98104 18.8595 6.5 17.2602 6.5Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  ),
  payout: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <path
        d="M10 7H16C18.8284 7 20.2426 7 21.1213 7.87868C22 8.75736 22 10.1716 22 13V15C22 17.8284 22 19.2426 21.1213 20.1213C20.2426 21 18.8284 21 16 21H10C6.22876 21 4.34315 21 3.17157 19.8284C2 18.6569 2 16.7712 2 13V11C2 7.22876 2 5.34315 3.17157 4.17157C4.34315 3 6.22876 3 10 3H14C14.93 3 15.395 3 15.7765 3.10222C16.8117 3.37962 17.6204 4.18827 17.8978 5.22354C18 5.60504 18 6.07003 18 7"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  listings: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="matrix(1 0 0 -1 16 8.00024)"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.77423 11.1439C1.77108 12.2643 1.7495 13.9546 2.67016 15.1437C4.49711 17.5033 6.49674 19.5029 8.85633 21.3298C10.0454 22.2505 11.7357 22.2289 12.8561 21.2258C15.8979 18.5022 18.6835 15.6559 21.3719 12.5279C21.6377 12.2187 21.8039 11.8397 21.8412 11.4336C22.0062 9.63798 22.3452 4.46467 20.9403 3.05974C19.5353 1.65481 14.362 1.99377 12.5664 2.15876C12.1603 2.19608 11.7813 2.36233 11.472 2.62811C8.34412 5.31646 5.49781 8.10211 2.77423 11.1439Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <path
        d="M7 14.0002L10 17.0002"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  upload: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10V11H17C18.933 11 20.5 12.567 20.5 14.5C20.5 16.433 18.933 18 17 18H16C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20H17C20.0376 20 22.5 17.5376 22.5 14.5C22.5 11.7793 20.5245 9.51997 17.9296 9.07824C17.4862 6.20213 15.0003 4 12 4C8.99974 4 6.51381 6.20213 6.07036 9.07824C3.47551 9.51997 1.5 11.7793 1.5 14.5C1.5 17.5376 3.96243 20 7 20H8C8.55228 20 9 19.5523 9 19C9 18.4477 8.55228 18 8 18H7C5.067 18 3.5 16.433 3.5 14.5C3.5 12.567 5.067 11 7 11H8V10ZM15.7071 13.2929L12.7071 10.2929C12.3166 9.90237 11.6834 9.90237 11.2929 10.2929L8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071C8.68342 15.0976 9.31658 15.0976 9.70711 14.7071L11 13.4142V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13.4142L14.2929 14.7071C14.6834 15.0976 15.3166 15.0976 15.7071 14.7071C16.0976 14.3166 16.0976 13.6834 15.7071 13.2929Z"
          fill="#000000"
        ></path>{' '}
      </g>
    </svg>
  ),
  upArrow: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 15L11.2929 9.70711C11.6262 9.37377 11.7929 9.20711 12 9.20711C12.2071 9.20711 12.3738 9.37377 12.7071 9.70711L18 15"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  downArrow: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9L11.2929 14.2929C11.6262 14.6262 11.7929 14.7929 12 14.7929C12.2071 14.7929 12.3738 14.6262 12.7071 14.2929L18 9"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  profile: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <circle cx="16.5" cy="7.5" r="1.5" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
      <path
        d="M2 14.1354C2.66663 14.0455 3.3406 14.0011 4.01569 14.0027C6.87163 13.9466 9.65761 14.7729 11.8765 16.3342C13.9345 17.7821 15.3805 19.7749 16 22"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13 18C14.7015 16.6733 16.5345 15.9928 18.3862 16.0001C19.4362 15.999 20.4812 16.2216 21.5 16.6617"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  dashboard: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="8" height="10" rx="2" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
      <rect x="2" y="16" width="8" height="6" rx="2" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
      <rect
        x="14"
        y="12"
        width="8"
        height="10"
        rx="2"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
      />
      <rect x="14" y="2" width="8" height="6" rx="2" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
    </svg>
  ),
  close: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22676 4.22676C4.5291 3.92441 5.01929 3.92441 5.32163 4.22676L12 10.9051L18.6784 4.22676C18.9807 3.92441 19.4709 3.92441 19.7732 4.22676C20.0756 4.5291 20.0756 5.01929 19.7732 5.32163L13.0949 12L19.7732 18.6784C20.0756 18.9807 20.0756 19.4709 19.7732 19.7732C19.4709 20.0756 18.9807 20.0756 18.6784 19.7732L12 13.0949L5.32163 19.7732C5.01929 20.0756 4.5291 20.0756 4.22676 19.7732C3.92441 19.4709 3.92441 18.9807 4.22676 18.6784L10.9051 12L4.22676 5.32163C3.92441 5.01929 3.92441 4.5291 4.22676 4.22676Z"
        fill="#030D45"
      />
    </svg>
  ),
  shipping: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="18" r="2" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
      <circle cx="7" cy="18" r="2" stroke={ICON_STROKE_COLOR} strokeWidth="1.5" />
      <path
        d="M5 17.9724C3.90328 17.9178 3.2191 17.7546 2.73223 17.2678C2.24536 16.7809 2.08222 16.0967 2.02755 15M9 18H15M19 17.9724C20.0967 17.9178 20.7809 17.7546 21.2678 17.2678C22 16.5355 22 15.357 22 13V11H17.3C16.5555 11 16.1832 11 15.882 10.9021C15.2731 10.7043 14.7957 10.2269 14.5979 9.61803C14.5 9.31677 14.5 8.94451 14.5 8.2C14.5 7.08323 14.5 6.52485 14.3532 6.07295C14.0564 5.15964 13.3404 4.44358 12.4271 4.14683C11.9752 4 11.4168 4 10.3 4H2"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8H8"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11H6"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 6H16.3212C17.7766 6 18.5042 6 19.0964 6.35371C19.6886 6.70742 20.0336 7.34811 20.7236 8.6295L22 11"
        stroke={ICON_STROKE_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  info: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.53846C7.32682 3.53846 3.53846 7.32682 3.53846 12C3.53846 16.6732 7.32682 20.4615 12 20.4615C16.6732 20.4615 20.4615 16.6732 20.4615 12C20.4615 7.32682 16.6732 3.53846 12 3.53846ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        fill="#030D45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.15385C11.2919 8.15385 10.7179 8.72784 10.7179 9.4359C10.7179 9.86073 10.3736 10.2051 9.94872 10.2051C9.52388 10.2051 9.17949 9.86073 9.17949 9.4359C9.17949 7.87817 10.4423 6.61539 12 6.61539C13.5577 6.61539 14.8205 7.87817 14.8205 9.4359C14.8205 9.99624 14.6563 10.5206 14.3731 10.9606C14.1958 11.2363 13.9884 11.4994 13.7972 11.7371C13.7622 11.7805 13.7279 11.8231 13.6941 11.8649C13.5355 12.0612 13.3898 12.2415 13.2553 12.4264C12.9234 12.8826 12.7692 13.2253 12.7692 13.5385C12.7692 13.9633 12.4248 14.3077 12 14.3077C11.5752 14.3077 11.2308 13.9633 11.2308 13.5385C11.2308 12.7187 11.6332 12.0408 12.0112 11.5213C12.171 11.3018 12.346 11.0853 12.5051 10.8884C12.537 10.849 12.5683 10.8103 12.5986 10.7726C12.7885 10.5365 12.9502 10.3289 13.0794 10.1282C13.2076 9.92891 13.2821 9.69227 13.2821 9.4359C13.2821 8.72784 12.7081 8.15385 12 8.15385Z"
        fill="#030D45"
      />
      <path
        d="M13.0256 16.6154C13.0256 17.1818 12.5664 17.641 12 17.641C11.4336 17.641 10.9744 17.1818 10.9744 16.6154C10.9744 16.0489 11.4336 15.5897 12 15.5897C12.5664 15.5897 13.0256 16.0489 13.0256 16.6154Z"
        fill="#030D45"
      />
    </svg>
  ),
  trackingPlane: (
    <svg fill="#000000" height="15px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 128 128">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path d="M56.1,11.2c0-9.1,13.8-9.1,13.8,0.3v38.3L123.4,82v14.1L70.1,78.6v28.6l12.3,9.7V128l-19-5.9l-19,5.9v-11.2l12.2-9.7V78.6 L3.2,96.1V82l52.8-32.2V11.2z"></path>{' '}
      </g>
    </svg>
  ),
  orderIssues: (
    <svg
      width={24}
      height={16}
      fill="#000000"
      viewBox="0 0 1920 1920"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          d="M960 1242.342c62.23 0 112.941 50.71 112.941 112.94 0 62.231-50.71 112.942-112.941 112.942-62.23 0-112.941-50.71-112.941-112.941 0-62.23 50.71-112.941 112.941-112.941Zm89.336-892.01c114.862 29.704 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.304-35.464 261.46-45.515 77.138-121.186 133.947-207.586 156.084-13.779 3.614-27.783 14.795-27.783 31.962v91.595H903.529v-91.595c0-66.183 46.306-124.235 112.716-141.29 57.6-14.795 107.971-52.743 138.353-104.131 30.833-52.292 39.19-114.635 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.183-143.435-3.953-199.341 39.304-55.68 43.143-87.642 108.31-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01 83.69-64.828 191.774-86.287 296.696-59.294ZM960-.01c-529.355 0-960 430.644-960 960 0 529.355 430.645 960 960 960 529.468 0 960-430.645 960-960 0-529.356-430.532-960-960-960"
          fillRule="evenodd"
        ></path>{' '}
      </g>
    </svg>
  ),
  deliveryInfo: (
    <svg
      width={24}
      height={16}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <rect x="0" fill="none" width="24" height="24"></rect>{' '}
        <g>
          {' '}
          <path d="M18 8h-2V7c0-1.105-.895-2-2-2H4c-1.105 0-2 .895-2 2v10h2c0 1.657 1.343 3 3 3s3-1.343 3-3h4c0 1.657 1.343 3 3 3s3-1.343 3-3h2v-5l-4-4zM7 18.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zM4 14V7h10v7H4zm13 4.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z"></path>{' '}
        </g>{' '}
      </g>
    </svg>
  ),
  checkmark: (
    <svg
      fill="#000000"
      viewBox="0 0 32 32"
      width={20}
      height={20}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <title>checkmark</title>{' '}
        <path
          fill="#3ac200"
          d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"
        ></path>{' '}
      </g>
    </svg>
  ),
  checkmarkLarge: (
    <svg
      fill="#000000"
      viewBox="0 0 32 32"
      width={32}
      height={32}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <title>checkmark</title>{' '}
        <path
          fill="#3ac200"
          d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"
        ></path>{' '}
      </g>
    </svg>
  ),
  backIcon: (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40388 8.20006L7.68063 2.67289C7.78898 2.57257 7.87392 2.44758 7.92892 2.30754C7.98392 2.16749 8.00752 2.01612 7.99791 1.86504C7.98829 1.71396 7.94572 1.56719 7.87347 1.43602C7.80121 1.30485 7.70119 1.19276 7.58109 1.10836C7.38115 0.956741 7.13643 0.884033 6.89021 0.903102C6.644 0.922172 6.41216 1.03179 6.23573 1.21256L0.306793 7.4699C0.210103 7.5627 0.132902 7.67554 0.0800877 7.80123C0.0272732 7.92693 0 8.06275 0 8.20006C0 8.33738 0.0272732 8.4732 0.0800877 8.59889C0.132902 8.72459 0.210103 8.83742 0.306793 8.93023L6.23573 15.1876C6.41216 15.3683 6.644 15.478 6.89021 15.497C7.13643 15.5161 7.38115 15.4434 7.58109 15.2918C7.70119 15.2074 7.80121 15.0953 7.87347 14.9641C7.94572 14.8329 7.98829 14.6862 7.99791 14.5351C8.00752 14.384 7.98392 14.2326 7.92892 14.0926C7.87392 13.9525 7.78898 13.8276 7.68063 13.7272L2.40388 8.20006Z"
        fill="#000"
      />
    </svg>
  ),
  dashboardHome: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 17C14.2005 17.6224 13.1502 18 12 18C10.8498 18 9.79952 17.6224 9 17"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.35139 13.2135C1.99837 10.9162 1.82186 9.76763 2.25617 8.74938C2.69047 7.73112 3.65403 7.03443 5.58114 5.64106L7.02099 4.6C9.41829 2.86667 10.6169 2 12 2C13.3831 2 14.5817 2.86667 16.979 4.6L18.4189 5.64106C20.346 7.03443 21.3095 7.73112 21.7438 8.74938C22.1781 9.76763 22.0016 10.9162 21.6486 13.2135L21.3476 15.1724C20.8471 18.4289 20.5969 20.0572 19.429 21.0286C18.2611 22 16.5537 22 13.1388 22H10.8612C7.44633 22 5.73891 22 4.571 21.0286C3.40309 20.0572 3.15287 18.4289 2.65243 15.1724L2.35139 13.2135Z"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ),
  dashboardPayouts: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14Z"
        stroke="#2A353D"
        strokeWidth="1.5"
      />
      <path
        d="M10 7H16C18.8284 7 20.2426 7 21.1213 7.87868C22 8.75736 22 10.1716 22 13V15C22 17.8284 22 19.2426 21.1213 20.1213C20.2426 21 18.8284 21 16 21H10C6.22876 21 4.34315 21 3.17157 19.8284C2 18.6569 2 16.7712 2 13V11C2 7.22876 2 5.34315 3.17157 4.17157C4.34315 3 6.22876 3 10 3H14C14.93 3 15.395 3 15.7765 3.10222C16.8117 3.37962 17.6204 4.18827 17.8978 5.22354C18 5.60504 18 6.07003 18 7"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  dashboardInbox: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 18.6458V8.05426C4 5.20025 4 3.77325 4.87868 2.88663C5.75736 2 7.17157 2 10 2H14C16.8284 2 18.2426 2 19.1213 2.88663C20 3.77325 20 5.20025 20 8.05426V18.6458C20 20.1575 20 20.9133 19.538 21.2108C18.7831 21.6971 17.6161 20.6774 17.0291 20.3073C16.5441 20.0014 16.3017 19.8485 16.0325 19.8397C15.7417 19.8301 15.4949 19.9768 14.9709 20.3073L13.06 21.5124C12.5445 21.8374 12.2868 22 12 22C11.7132 22 11.4555 21.8374 10.94 21.5124L9.02913 20.3073C8.54415 20.0014 8.30166 19.8485 8.03253 19.8397C7.74172 19.8301 7.49493 19.9768 6.97087 20.3073C6.38395 20.6774 5.21687 21.6971 4.46195 21.2108C4 20.9133 4 20.1575 4 18.6458Z"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11H8"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7L8 7"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  offerIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.46433 9.34375C2.21579 9.34375 1.98899 9.14229 2.00041 8.87895C2.06733 7.33687 2.25481 6.33298 2.78008 5.53884C3.08228 5.08196 3.45765 4.68459 3.88923 4.36468C5.05575 3.5 6.70139 3.5 9.99266 3.5H14.0074C17.2986 3.5 18.9443 3.5 20.1108 4.36468C20.5424 4.68459 20.9177 5.08196 21.2199 5.53884C21.7452 6.33289 21.9327 7.33665 21.9996 8.87843C22.011 9.14208 21.7839 9.34375 21.5351 9.34375C20.1493 9.34375 19.0259 10.533 19.0259 12C19.0259 13.467 20.1493 14.6562 21.5351 14.6562C21.7839 14.6562 22.011 14.8579 21.9996 15.1216C21.9327 16.6634 21.7452 17.6671 21.2199 18.4612C20.9177 18.918 20.5424 19.3154 20.1108 19.6353C18.9443 20.5 17.2986 20.5 14.0074 20.5H9.99266C6.70139 20.5 5.05575 20.5 3.88923 19.6353C3.45765 19.3154 3.08228 18.918 2.78008 18.4612C2.25481 17.667 2.06733 16.6631 2.00041 15.1211C1.98899 14.8577 2.21579 14.6562 2.46433 14.6562C3.85012 14.6562 4.97352 13.467 4.97352 12C4.97352 10.533 3.85012 9.34375 2.46433 9.34375Z"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14.5L14.5 9.5"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9.5H9.51122M14.4888 14.5H14.5"
        stroke="#2A353D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  tabRibbon: (
    <svg
      width="800"
      height="780"
      viewBox="0 0 800 780"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.24"
        d="M310.539 676.953C301.636 670.931 290.795 667.378 279.269 667.392C271.58 667.392 264.177 668.954 257.483 671.798C247.43 676.05 238.928 683.117 232.894 692.045C226.86 700.948 223.319 711.789 223.344 723.314C223.331 730.989 224.895 738.393 227.738 745.086C232 755.153 239.056 763.654 247.972 769.675C256.888 775.723 267.741 779.25 279.267 779.25C286.942 779.25 294.335 777.686 301.027 774.843C311.092 770.592 319.594 763.511 325.63 754.597C331.652 745.693 335.192 734.839 335.192 723.312C335.192 715.639 333.616 708.247 330.786 701.54C326.524 691.476 319.455 682.975 310.539 676.953ZM300.047 732.089C298.341 736.12 295.46 739.597 291.855 742.025C288.236 744.454 283.999 745.862 279.269 745.875C276.103 745.862 273.171 745.242 270.496 744.09C266.477 742.412 262.988 739.517 260.558 735.925C258.128 732.293 256.72 728.043 256.708 723.312C256.722 720.159 257.353 717.239 258.478 714.539C260.17 710.52 263.066 707.045 266.669 704.604C270.288 702.175 274.539 700.779 279.269 700.765C282.422 700.765 285.342 701.4 288.03 702.536C292.061 704.228 295.524 707.109 297.953 710.728C300.396 714.345 301.803 718.584 301.803 723.312C301.803 726.481 301.172 729.401 300.047 732.089Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M338.321 433.076C349.692 429.768 356.23 417.842 352.896 406.457L297.308 216.151C294 204.767 282.086 198.242 270.702 201.562C259.319 204.884 252.806 216.797 256.114 228.168L311.702 418.5C315.035 429.872 326.949 436.409 338.321 433.076Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M401.531 404.339C404.866 415.723 416.78 422.248 428.149 418.926C439.521 415.606 446.06 403.693 442.725 392.309L391.169 215.79C387.861 204.407 375.947 197.881 364.564 201.19C353.18 204.523 346.655 216.437 349.975 227.807L401.531 404.339Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M491.491 390.603C494.811 401.987 506.738 408.512 518.108 405.192C529.48 401.87 536.017 389.943 532.683 378.573L485.042 215.429C481.721 204.045 469.808 197.52 458.436 200.854C447.053 204.162 440.527 216.089 443.849 227.459L491.491 390.603Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M581.164 375.951C584.499 387.336 596.411 393.861 607.781 390.539C619.153 387.206 625.691 375.306 622.37 363.922L578.889 215.054C575.581 203.672 563.655 197.159 552.271 200.467C540.9 203.801 534.375 215.714 537.696 227.084L581.164 375.951Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M263.635 603.445C257.586 603.445 251.928 602.229 246.733 600.045C238.967 596.764 232.286 591.233 227.61 584.281C223.113 577.6 220.45 569.731 220.244 561.1C220.475 550.8 223.758 541.861 229.521 534.714C232.505 531.031 236.188 527.801 240.658 525.126C245.077 522.49 250.285 520.397 256.397 519.079L638.17 456.94C663.11 452.87 682.956 433.8 687.995 409.028L734.694 179.453V179.428C735.236 176.765 735.496 174.053 735.496 171.365C735.496 162.087 732.316 153.017 726.347 145.703C718.644 136.297 707.119 130.831 694.96 130.831H166.335L148.325 68.9528V68.9653C140.055 41.3012 117.366 20.3434 89.1455 14.2965L24.5127 0.455903C13.5799 -1.89566 2.8049 5.08246 0.451773 16.0262C-1.88573 26.97 5.07677 37.7465 16.0361 40.0856L80.6424 53.9372C94.3893 56.8825 105.452 67.0903 109.483 80.5809L227.583 486.259C224.922 487.5 222.35 488.843 219.869 490.329C207.141 497.915 196.933 508.562 190.086 520.992C183.533 532.801 180.058 546.189 179.724 560.001H179.672V562.107H179.724C179.981 572.898 182.281 583.247 186.274 592.693C192.671 607.798 203.291 620.578 216.677 629.622C230.052 638.678 246.319 643.99 263.633 643.976H478.121C478.055 641.975 477.817 640.023 477.817 638.007C477.817 626.184 479.038 614.645 481.214 603.443H263.635V603.445ZM178.122 171.367H694.963H694.977L648.278 400.953C646.597 409.223 639.981 415.581 631.661 416.925L266.891 476.311L178.122 171.367Z"
        fill="black"
      />
      <path
        d="M658.756 496.765C580.75 496.765 517.513 560.003 517.513 638.009C517.513 716.015 580.75 779.253 658.756 779.253C736.775 779.253 800 716.015 800 638.009C800 560.003 736.775 496.765 658.756 496.765Z"
        fill="#C6488C"
      />
      <path
        d="M694.484 638.539L734.099 597.31L700.147 564.687L660.532 605.917L619.302 566.301L586.687 600.245L627.918 639.861L588.302 681.091L622.254 713.713L661.869 672.483L703.09 712.089L735.704 678.145L694.484 638.539Z"
        fill="white"
      />
    </svg>
  ),
  clock: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#f77f00" strokeWidth="1.5" />
      <path
        d="M12 8V12L14 14"
        stroke="#f77f00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  expiredClock: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#ED1B24" strokeWidth="1.5" />
      <path
        d="M11.992 15H12.001"
        stroke="#ED1B24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L12 8"
        stroke="#ED1B24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  emptyInbox: (
    <svg
      width="800"
      height="780"
      viewBox="0 0 800 780"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.24"
        d="M310.539 676.953C301.636 670.931 290.795 667.378 279.269 667.392C271.58 667.392 264.177 668.954 257.483 671.798C247.43 676.05 238.928 683.117 232.894 692.045C226.86 700.948 223.319 711.789 223.344 723.314C223.331 730.989 224.895 738.393 227.738 745.086C232 755.153 239.056 763.654 247.972 769.675C256.888 775.723 267.741 779.25 279.267 779.25C286.942 779.25 294.335 777.686 301.027 774.843C311.092 770.592 319.594 763.511 325.63 754.597C331.652 745.693 335.192 734.839 335.192 723.312C335.192 715.639 333.616 708.247 330.786 701.54C326.524 691.476 319.455 682.975 310.539 676.953ZM300.047 732.089C298.341 736.12 295.46 739.597 291.855 742.025C288.236 744.454 283.999 745.862 279.269 745.875C276.103 745.862 273.171 745.242 270.496 744.09C266.477 742.412 262.988 739.517 260.558 735.925C258.128 732.293 256.72 728.043 256.708 723.312C256.722 720.159 257.353 717.239 258.478 714.539C260.17 710.52 263.066 707.045 266.669 704.604C270.288 702.175 274.539 700.779 279.269 700.765C282.422 700.765 285.342 701.4 288.03 702.536C292.061 704.228 295.524 707.109 297.953 710.728C300.396 714.345 301.803 718.584 301.803 723.312C301.803 726.481 301.172 729.401 300.047 732.089Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M338.321 433.076C349.692 429.768 356.23 417.842 352.896 406.457L297.308 216.151C294 204.767 282.086 198.242 270.702 201.562C259.319 204.884 252.806 216.797 256.114 228.168L311.702 418.5C315.035 429.872 326.949 436.409 338.321 433.076Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M401.531 404.339C404.866 415.723 416.78 422.248 428.149 418.926C439.521 415.606 446.06 403.693 442.725 392.309L391.169 215.79C387.861 204.407 375.947 197.881 364.564 201.19C353.18 204.523 346.655 216.437 349.975 227.807L401.531 404.339Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M491.491 390.603C494.811 401.987 506.738 408.512 518.108 405.192C529.48 401.87 536.017 389.943 532.683 378.573L485.042 215.429C481.721 204.045 469.808 197.52 458.436 200.854C447.053 204.162 440.527 216.089 443.849 227.459L491.491 390.603Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M581.164 375.951C584.499 387.336 596.411 393.861 607.781 390.539C619.153 387.206 625.691 375.306 622.37 363.922L578.889 215.054C575.581 203.672 563.655 197.159 552.271 200.467C540.9 203.801 534.375 215.714 537.696 227.084L581.164 375.951Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M263.635 603.445C257.586 603.445 251.928 602.229 246.733 600.045C238.967 596.764 232.286 591.233 227.61 584.281C223.113 577.6 220.45 569.731 220.244 561.1C220.475 550.8 223.758 541.861 229.521 534.714C232.505 531.031 236.188 527.801 240.658 525.126C245.077 522.49 250.285 520.397 256.397 519.079L638.17 456.94C663.11 452.87 682.956 433.8 687.995 409.028L734.694 179.453V179.428C735.236 176.765 735.496 174.053 735.496 171.365C735.496 162.087 732.316 153.017 726.347 145.703C718.644 136.297 707.119 130.831 694.96 130.831H166.335L148.325 68.9528V68.9653C140.055 41.3012 117.366 20.3434 89.1455 14.2965L24.5127 0.455903C13.5799 -1.89566 2.8049 5.08246 0.451773 16.0262C-1.88573 26.97 5.07677 37.7465 16.0361 40.0856L80.6424 53.9372C94.3893 56.8825 105.452 67.0903 109.483 80.5809L227.583 486.259C224.922 487.5 222.35 488.843 219.869 490.329C207.141 497.915 196.933 508.562 190.086 520.992C183.533 532.801 180.058 546.189 179.724 560.001H179.672V562.107H179.724C179.981 572.898 182.281 583.247 186.274 592.693C192.671 607.798 203.291 620.578 216.677 629.622C230.052 638.678 246.319 643.99 263.633 643.976H478.121C478.055 641.975 477.817 640.023 477.817 638.007C477.817 626.184 479.038 614.645 481.214 603.443H263.635V603.445ZM178.122 171.367H694.963H694.977L648.278 400.953C646.597 409.223 639.981 415.581 631.661 416.925L266.891 476.311L178.122 171.367Z"
        fill="black"
      />
      <path
        d="M658.756 496.765C580.75 496.765 517.513 560.003 517.513 638.009C517.513 716.015 580.75 779.253 658.756 779.253C736.775 779.253 800 716.015 800 638.009C800 560.003 736.775 496.765 658.756 496.765Z"
        fill="#C6488C"
      />
      <path
        d="M694.484 638.539L734.099 597.31L700.147 564.687L660.532 605.917L619.302 566.301L586.687 600.245L627.918 639.861L588.302 681.091L622.254 713.713L661.869 672.483L703.09 712.089L735.704 678.145L694.484 638.539Z"
        fill="white"
      />
    </svg>
  ),
  addIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4V20"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12H20"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  subtractIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12L4 12"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  disabledAddIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4V20"
        stroke="#dedede"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12H20"
        stroke="#dedede"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  disabledSubtractIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12L4 12"
        stroke="#dedede"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  deleteIcon: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M21 5.5H3" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M16.0555 5.5L15.3729 4.09173C14.9194 3.15626 14.6926 2.68852 14.3015 2.39681C14.2148 2.3321 14.1229 2.27454 14.0268 2.2247C13.5937 2 13.0739 2 12.0343 2C10.9686 2 10.4358 2 9.99549 2.23412C9.89791 2.28601 9.80479 2.3459 9.71711 2.41317C9.32145 2.7167 9.10044 3.20155 8.65842 4.17126L8.05273 5.5"
        stroke="#2A353D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M9.5 16.5L9.5 10.5" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14.5 16.5L14.5 10.5" stroke="#2A353D" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ),
};

const Icons = ({ name }) => {
  return icons[name];
};

export default Icons;
