import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, Button, IconSpinner, Icons } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { searchListings } from '../../containers/CartPage/CartPage.duck';
import IconTrash from '../IconTrash/IconTrash';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './CartCard.module.css';
import { selectIsAuthenticated } from '../../ducks/auth.duck';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { getWishlist } from '../../util/dataExtractors';
import { useConfiguration } from '../../context/configurationContext';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const CartCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    currentCartItem,
    setStockDetails,
    stockDetails,
  } = props;

  const dispatch = useDispatch();
  const config = useConfiguration();
  const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intl, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  };


  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    setCurrentItem(currentCartItem?.item);
  }, [currentCartItem?.item?.stockCount]);

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [removeInProgress, setRemoveInProgress] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;

  const currentStock = currentListing && currentListing.currentStock;
  const slug = createSlug(title);

  let firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const [isHovered, setHovered] = useState('');

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  }

  const itemTotal =
    price && currentItem?.stockCount
      ? (price.amount / 100) * currentItem?.stockCount
      : (price.amount / 100) * 1;

  const availableQuantity =
    currentStock && currentStock.attributes && currentStock.attributes.quantity;

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const wishlist = isAuthenticated ? getWishlist(currentUser) : [];

  const onUpdateCurrentUser = array => {
    setRemoveInProgress(true);
    const params = { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({ publicData: { ...params } })
      .then(() => dispatch(searchListings()))
      .then(() => setRemoveInProgress(false));
  };

  const onToggleQuanity = type => {
    const currentItemParams = {
      ...currentItem,
      stockCount: type === 'add' ? currentItem?.stockCount + 1 : currentItem?.stockCount - 1,
    };

    stockDetails[currentCartItem.index] = currentItemParams;
    setCurrentItem(currentItemParams);
    setStockDetails(stockDetails);
  };

  return (
    <>
      <td>
        <div className={css.productDetails}>
          <div className={css.productDetailsLeft}>
            <div className={css.productImg}>
              <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                <AspectRatioWrapper
                  className={css.aspectRatioWrapper}
                  width={aspectWidth}
                  height={aspectHeight}
                  onMouseOver={() => setHovered('hover')}
                  onMouseOut={() => setHovered('')}
                >
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                  />
                </AspectRatioWrapper>
              </NamedLink>
            </div>
            <div className={css.info}>
              <div className={css.mainInfo}>
                <div className={css.title}>
                  <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                    {richText(title, {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    })}
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <p>
          <span>Test</span>
        </p>
      </td>
      <td>
        <div className={css.productDetails}>
          <div className={css.productDetailsRight}>
            <div className={css.stock}>
              <Button
                type="button"
                onClick={() => onToggleQuanity('subtract')}
                disabled={currentItem?.stockCount === 1}
              >
                <Icons
                  name={currentItem?.stockCount > 1 ? 'subtractIcon' : 'disabledSubtractIcon'}
                />
              </Button>
              <span className={css.stockCount}>{currentItem?.stockCount || 1}</span>
              <Button
                type="button"
                onClick={() => onToggleQuanity('add')}
                disabled={availableQuantity == currentItem?.stockCount}
              >
                <Icons
                  name={
                    availableQuantity == currentItem?.stockCount ? 'disabledAddIcon' : 'addIcon'
                  }
                />
              </Button>
            </div>
          </div>
        </div>
      </td>
      <td>
        <p>
          <span>{publicData?.freeShippingEnabled ? 'Free Shipping' : 'Shipping'}</span>
        </p>
      </td>
      <td>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            ${itemTotal && itemTotal.toFixed(2)}
          </div>
        </div>
      </td>
      <td style={{ textAlign: 'center' }}>
        <div className={css.favorite}>
          {removeInProgress ? (
            <IconSpinner />
          ) : wishlist && wishlist.findIndex(e => e == id) > -1 ? (
            <span
              className={css.bookmark}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (id) {
                  const index = wishlist && wishlist.findIndex(b => b == id);
                  if (index > -1) {
                    wishlist && wishlist.splice(index, 1);
                    onUpdateCurrentUser(wishlist);
                  } else {
                    wishlist.push(id);
                    onUpdateCurrentUser(Array.from(new Set(wishlist)));
                  }
                }
              }}
            >
              <IconTrash />
            </span>
          ) : null}
        </div>
      </td>
    </>
  );
};

CartCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

CartCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(CartCardComponent);
