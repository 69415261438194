import React from 'react';
import { useDropzone } from 'react-dropzone';
import IconUpload from '../IconUpload/IconUpload';

import css from './DropZone.module.css';

function DropZone(props) {
  const { onAttachmentUpload } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogv', '.ogg'], // .ogv for video/ogg
    },
    maxSize: 5242880, // Maximum file size of 5 MB
    onDrop: acceptedFiles => {
      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        onAttachmentUpload(file);
      }
    },
  });

  return (
    <div {...getRootProps({ className: css.dropzone })}>
      <input {...getInputProps()} />
      <IconUpload />
      <h2 className={css.chooseImage}>Upload</h2>
    </div>
  );
}

export default DropZone;
