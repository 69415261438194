import { toKebabCase } from '../util/genericHelpers';

export const filters = [
  {
    id: 'category_appliances',
    parentId: 'appliances',
    label: 'Appliances',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcatergory_appliances'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'small_kitchen_appliances', label: 'Small Kitchen Appliances' },
        { key: 'household_appliances', label: 'Household Appliances' },
        { key: 'specialty_appliances', label: 'Specialty Appliances' },
        { key: 'appliances_slider', label: 'Appliances Slider' },
        { key: 'appliances_online', label: 'Appliances Online' },
        { key: 'appliances_vinyl_sticker', label: 'Appliances Vinyl Sticker' },
        { key: 'appliances_mat', label: 'Appliances Mat' },
        { key: 'appliances_cable_organizer', label: 'Appliances Cable Organizer' },
        { key: 'appliances_shelf', label: 'Appliances Shelf' },
      ],
    },
  },
  {
    id: 'category_appsAndGames',
    label: 'Apps & Games',
    parentId: 'apps-and-games',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcatergory_appsAndGames'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'action', label: 'Action' },
        { key: 'adventure', label: 'Adventure' },
        { key: 'arcade', label: 'Arcade' },
        { key: 'battle_royale', label: 'Battle Royale' },
        { key: 'casual', label: 'Casual' },
        { key: 'card', label: 'Card' },
        { key: 'role_playing', label: 'Role-Playing' },
        { key: 'moba', label: 'MOBA – Multiplayer Online Battle Arena' },
        { key: 'puzzle', label: 'Puzzle' },
        { key: 'racing', label: 'Racing' },
        { key: 'strategy', label: 'Strategy' },
        { key: 'sports', label: 'Sports' },
        { key: 'trivia', label: 'Trivia' },
        { key: 'word', label: 'Word' },
      ],
    },
  },
  {
    id: 'category_automotivePartsAndAccessories',
    label: 'Automotive Parts & Accessories',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'automotive-parts-and-accessories',
    queryParamNames: ['pub_subcatergory_automotivePartsAndAccessories'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'car_parts', label: 'Car Parts' },
        { key: 'car_accessories', label: 'Car Accessories' },
        { key: 'car_care', label: 'Car Care' },
        { key: 'car_tools_and_equipment', label: 'Car Tools & Equipment' },
        { key: 'car_electronics', label: 'Car Electronics' },
        { key: 'tyres_and_wheels', label: 'Tyres & Wheels' },
        { key: 'motorcycle_parts_and_accessories', label: 'Motorcycle Parts & Accessories' },
        { key: 'rv_parts_and_accessories', label: 'RV Parts & Accessories' },
        { key: 'oils_and_fluids', label: 'Oils & Fluids' },
        { key: 'towing_racks_and_storage', label: 'Towing, Racks & Storage' },
      ],
    },
  },
  {
    id: 'category_artsCraftsAndSewing',
    label: 'Arts, Crafts & Sewing',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'arts-crafts-and-sewing',
    queryParamNames: ['pub_subcatergory_artsCraftsAndSewing'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'printmaking', label: 'Printmaking' },
        { key: 'engraving', label: 'Engraving' },
        { key: 'jewelry_making_and_repair', label: 'Jewelry Making & Repair' },
        { key: 'ceramics_and_pottery_crafts', label: 'Ceramics and Pottery Crafts' },
        {
          key: 'painting_and_drawing_and_art_supplies',
          label: 'Painting & Drawing & Art Supplies',
        },
        { key: 'paper_crafts', label: 'Paper Crafts' },
        { key: 'specialty_crafts_and_hobbies', label: 'Specialty Crafts & Hobbies' },
        { key: 'leather_crafts', label: 'Leather Crafts' },
      ],
    },
  },
  {
    id: 'category_baby',
    label: 'Baby',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'baby',
    queryParamNames: ['pub_subcatergory_baby'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_baby_products', label: 'All Baby Products' },
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'baby_care', label: 'Baby Care' },
        { key: 'baby_clothes', label: 'Baby Clothes' },
        { key: 'baby_food', label: 'Baby Food' },
        { key: 'nursing_and_feeding', label: 'Nursing & Feeding' },
        { key: 'baby_toys', label: 'Baby Toys' },
        { key: 'baby_shoes', label: 'Baby Shoes' },
        { key: 'boys_clothes', label: "Boys' Clothes" },
        { key: 'boys_shoes', label: "Boys' Shoes" },
        { key: 'girls_clothes', label: "Girls' Clothes" },
        { key: 'girls_shoes', label: "Girls' Shoes" },
        { key: 'toys_and_games', label: 'Toys & Games' },
        { key: 'baby_wishlist', label: 'Baby Wishlist' },
        { key: 'shop_by_age', label: 'Shop By Age' },
        { key: 'subscribe_and_save', label: 'Subscribe & Save' },
      ],
    },
  },
  {
    id: 'category_books',
    label: 'Books',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'books',
    queryParamNames: ['pub_subcatergory_books'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_books', label: 'All Books' },
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'new_releases', label: 'New Releases' },
        { key: 'trending_now', label: 'Trending Now' },
        { key: 'most_wished_for', label: 'Most Wished For' },
        { key: 'textbooks', label: 'Textbooks' },
        { key: 'childrens_books', label: "Children's Books" },
      ],
    },
  },
  {
    id: 'category_beautyAndPersonalCare',
    label: 'Beauty & Personal Care',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'beauty-and-personal-care',
    queryParamNames: ['pub_subcatergory_beautyAndPersonalCare'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_beauty', label: 'All Beauty' },
        { key: 'premium_beauty', label: 'Premium Beauty' },
        { key: 'salon_beauty', label: 'Salon Beauty' },
        { key: 'makeup', label: 'Makeup' },
        { key: 'skin_care', label: 'Skin Care' },
        { key: 'sun_care', label: 'Sun Care' },
        { key: 'hair_care', label: 'Hair Care' },
        { key: 'tools_and_accessories', label: 'Tools & Accessories' },
        { key: 'mens_grooming', label: "Men's Grooming" },
        { key: 'subscribe_and_save', label: 'Subscribe & Save' },
      ],
    },
  },
  {
    id: 'category_cDsAndVinyl',
    label: 'CDs & Vinyl',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'cds-and-vinyl',
    queryParamNames: ['pub_subcatergory_cDsAndVinyl'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new_arrivals', label: 'New Arrivals' },
        { key: 'women', label: 'Women' },
        { key: 'men', label: 'Men' },
        { key: 'girls', label: 'Girls' },
        { key: 'boys', label: 'Boys' },
        { key: 'baby', label: 'Baby' },
        { key: 'luggage', label: 'Luggage' },
        { key: 'sales_and_deals', label: 'Sales & Deals' },
      ],
    },
  },
  {
    id: 'category_cellPhonesAndAccessories',
    label: 'Cell Phones & Accessories',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'cell-phones-and-accessories',
    queryParamNames: ['pub_subcatergory_cellPhonesAndAccessories'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new_arrivals', label: 'New Arrivals' },
        { key: 'women', label: 'Women' },
        { key: 'men', label: 'Men' },
        { key: 'girls', label: 'Girls' },
        { key: 'boys', label: 'Boys' },
        { key: 'baby', label: 'Baby' },
        { key: 'luggage', label: 'Luggage' },
        { key: 'sales_and_deals', label: 'Sales & Deals' },
      ],
    },
  },
  {
    id: 'category_clothingShoesAndJewelry',
    label: 'Clothing, Shoes and Jewelry',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'clothing-shoes-and-jewelry',
    queryParamNames: ['pub_subcatergory_clothingShoesAndJewelry'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'new_arrivals', label: 'New Arrivals' },
        { key: 'women', label: 'Women' },
        { key: 'men', label: 'Men' },
        { key: 'girls', label: 'Girls' },
        { key: 'boys', label: 'Boys' },
        { key: 'baby', label: 'Baby' },
        { key: 'luggage', label: 'Luggage' },
        { key: 'sales_and_deals', label: 'Sales & Deals' },
      ],
      filter: {
        key: 'size',
        label: 'Size',
      },
    },
  },
  {
    id: 'category_collectiblesAndFineArt',
    label: 'Collectibles & Fine Art',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'collectibles-and-fine-art',
    queryParamNames: ['pub_subcatergory_collectiblesAndFineArt'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_computers_and_accessories', label: 'All Computers & Accessories' },
        { key: 'laptops', label: 'Laptops' },
        { key: 'desktops', label: 'Desktops' },
        { key: 'monitors', label: 'Monitors' },
        { key: 'accessories', label: 'Accessories' },
        { key: 'networking', label: 'Networking' },
        { key: 'drives_and_storage', label: 'Drives & Storage' },
        { key: 'computer_parts_and_components', label: 'Computer Parts & Components' },
        { key: 'pc_gaming', label: 'PC Gaming' },
        { key: 'printers_and_ink', label: 'Printers & Ink' },
      ],
    },
  },
  {
    id: 'category_computers',
    label: 'Computers',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'computers',
    queryParamNames: ['pub_subcatergory_computers'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_computers_and_accessories', label: 'All Computers & Accessories' },
        { key: 'laptops', label: 'Laptops' },
        { key: 'desktops', label: 'Desktops' },
        { key: 'monitors', label: 'Monitors' },
        { key: 'accessories', label: 'Accessories' },
        { key: 'networking', label: 'Networking' },
        { key: 'drives_and_storage', label: 'Drives & Storage' },
        { key: 'computer_parts_and_components', label: 'Computer Parts & Components' },
        { key: 'pc_gaming', label: 'PC Gaming' },
        { key: 'printers_and_ink', label: 'Printers & Ink' },
      ],
    },
  },
  {
    id: 'category_electronics',
    label: 'Electronics',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'electronics',
    queryParamNames: ['pub_subcatergory_electronics'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_electronics', label: 'All Electronics' },
        { key: 'camera_and_photo', label: 'Camera and Photo' },
        { key: 'home_cinema_and_audio', label: 'Home Cinema & Audio' },
        { key: 'speakers', label: 'Speakers' },
        { key: 'headphones', label: 'Headphones' },
        { key: 'televisions', label: 'Televisions' },
        { key: 'pc_and_video_games', label: 'PC & Video Games' },
        { key: 'musical_instruments', label: 'Musical Instruments' },
        { key: 'smart_home', label: 'Smart Home' },
        { key: 'wearable_technology', label: 'Wearable Technology' },
        { key: 'gps_navigation_and_accessories', label: 'GPS, Navigation & Accessories' },
      ],
    },
  },
  {
    id: 'category_gardenAndOutdoor',
    label: 'Garden & Outdoor',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'garden-and-outdoor',
    queryParamNames: ['pub_subcatergory_gardenAndOutdoor'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_garden', label: 'All Garden' },
        { key: 'gardening', label: 'Gardening' },
        { key: 'barbeque_and_outdoor_dining', label: 'Barbeque & Outdoor Dining' },
        { key: 'mowers_and_outdoor_power_tools', label: 'Mowers & Outdoor Power Tools' },
        { key: 'outdoor_furniture', label: 'Outdoor Furniture' },
        { key: 'pools_hot_tubs_and_supplies', label: 'Pools, Hot Tubs & Supplies' },
      ],
    },
  },
  {
    id: 'category_groceryAndGourmetFood',
    label: 'Grocery & Gourmet Food',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'grocery-and-gourmet-food',
    queryParamNames: ['pub_subcatergory_groceryAndGourmetFood'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_pantry_food_and_drinks', label: 'All Pantry Food & Drinks' },
        { key: 'baby_food', label: 'Baby Food' },
        { key: 'cereals_and_breakfast_bars', label: 'Cereals & Breakfast Bars' },
        { key: 'coffee', label: 'Coffee' },
        { key: 'drinks', label: 'Drinks' },
        { key: 'herbs_spices_and_seasoning', label: 'Herbs, Spices & Seasoning' },
        { key: 'jams_honey_and_spreads', label: 'Jams, Honey & Spreads' },
        { key: 'snacks', label: 'Snacks' },
        { key: 'tea', label: 'Tea' },
      ],
    },
  },
  {
    id: 'category_handmade',
    label: 'Handmade',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'handmade',
    queryParamNames: ['pub_subcatergory_handmade'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'subscribe_and_save', label: 'Subscribe & Save' },
        { key: 'health_and_personal_care', label: 'Health & Personal Care' },
        { key: 'bath_and_body', label: 'Bath & Body' },
        { key: 'baby_care', label: 'Baby Care' },
        { key: 'dental_care', label: 'Dental Care' },
        { key: 'face_masks', label: 'Face Masks' },
        { key: 'sex_and_sensuality', label: 'Sex & Sensuality' },
        { key: 'vitamins_and_supplements', label: 'Vitamins & Supplements' },
        { key: 'household_and_cleaning', label: 'Household & Cleaning' },
        { key: 'dishwashing_supplies', label: 'Dishwashing Supplies' },
        { key: 'laundry', label: 'Laundry' },
        { key: 'tissues_toilet_paper_and_sprays', label: 'Tissues, Toilet Paper & Sprays' },
      ],
    },
  },
  {
    id: 'category_healthHouseholdAndBabyCare',
    label: 'Health, Household & Baby Care',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'health-household-and-baby-care',
    queryParamNames: ['pub_subcatergory_healthHouseholdAndBabyCare'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'subscribe_and_save', label: 'Subscribe & Save' },
        { key: 'health_and_personal_care', label: 'Health & Personal Care' },
        { key: 'bath_and_body', label: 'Bath & Body' },
        { key: 'baby_care', label: 'Baby Care' },
        { key: 'dental_care', label: 'Dental Care' },
        { key: 'face_masks', label: 'Face Masks' },
        { key: 'sex_and_sensuality', label: 'Sex & Sensuality' },
        { key: 'vitamins_and_supplements', label: 'Vitamins & Supplements' },
        { key: 'household_and_cleaning', label: 'Household & Cleaning' },
        { key: 'dishwashing_supplies', label: 'Dishwashing Supplies' },
        { key: 'laundry', label: 'Laundry' },
        { key: 'tissues_toilet_paper_and_sprays', label: 'Tissues, Toilet Paper & Sprays' },
      ],
    },
  },
  {
    id: 'category_home',
    label: 'Home',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'home',
    queryParamNames: ['pub_subcatergory_home'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_home', label: 'All Home' },
        { key: 'bedding_and_linen', label: 'Bedding & Linen' },
        { key: 'bathroom_accessories', label: 'Bathroom Accessories' },
        { key: 'home_decor', label: 'Home Décor' },
        { key: 'lighting', label: 'Lighting' },
        { key: 'storage_and_organisation', label: 'Storage & Organisation' },
        { key: 'household_and_cleaning_supplies', label: 'Household & Cleaning Supplies' },
        { key: 'discover_furniture_and_decor', label: 'Discover Furniture & Decor' },
      ],
    },
  },
  {
    id: 'category_industrialAndScientific',
    label: 'Industrial & Scientific',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'industrial-and-scientific',
    queryParamNames: ['pub_subcatergory_industrialAndScientific'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_kitchen_and_dining', label: 'All Kitchen & Dining' },
        { key: 'small_kitchen_appliances', label: 'Small Kitchen Appliances' },
        { key: 'tools_gadgets_and_barware', label: 'Tools, Gadgets & Barware' },
        { key: 'drinks_and_coffee', label: 'Drinks & Coffee' },
        { key: 'bakeware', label: 'Bakeware' },
        { key: 'cookware', label: 'Cookware' },
      ],
    },
  },
  {
    id: 'category_luggageAndTravelGear',
    label: 'Luggage & Travel Gear',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'luggage-and-travel-gear',
    queryParamNames: ['pub_subcatergory_luggageAndTravelGear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'travel_accessories', label: 'Travel Accessories' },
        { key: 'backpacks', label: 'Backpacks' },
        { key: 'business_and_laptop_bags', label: 'Business & Laptop Bags' },
        { key: 'suitcases', label: 'Suitcases' },
        { key: 'carry_ons', label: 'Carry-Ons' },
        { key: 'messenger_bags', label: 'Messenger Bags' },
        { key: 'childrens_luggage', label: "Children's Luggage" },
        { key: 'waist_packs', label: 'Waist Packs' },
        { key: 'shop_the_travel_store', label: 'Shop the Travel Store' },
      ],
    },
  },
  {
    id: 'category_moviesAndTV',
    label: 'Movies & TV',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'movies-and-tv',
    queryParamNames: ['pub_subcatergory_moviesAndTV'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_vinyl_and_cds', label: 'All Vinyl & CDs' },
        { key: 'music_best_sellers', label: 'Music Best Sellers' },
        { key: 'music_trending_now', label: 'Music Trending Now' },
        { key: 'vinyl_cds', label: 'Vinyl CDs' },
        { key: 'all_movies_and_tv_shows', label: 'All Movies & TV Shows' },
        { key: 'movies_best_sellers', label: 'Movies Best Sellers' },
        { key: 'movies_new_releases', label: 'Movies New Releases' },
        { key: 'movies', label: 'Movies' },
        { key: 'tv_shows', label: 'TV Shows' },
        { key: '4k_ultra_hd', label: '4k Ultra HD' },
        { key: 'blu_ray', label: 'Blu-ray' },
      ],
    },
  },
  {
    id: 'category_musicalInstruments',
    label: 'Musical Instruments',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'musical-instruments',
    queryParamNames: ['pub_subcatergory_musicalInstruments'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_stationery_and_office', label: 'All Stationery & Office' },
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'arts_and_crafts', label: 'Arts & Crafts' },
        { key: 'calendars_and_personal_organisers', label: 'Calendars & Personal Organisers' },
        { key: 'office_furniture_and_lighting', label: 'Office Furniture & Lighting' },
        { key: 'office_electronics', label: 'Office Electronics' },
        { key: 'office_organisation', label: 'Office Organisation' },
        { key: 'office_supplies', label: 'Office Supplies' },
        { key: 'paper_products', label: 'Paper Products' },
        { key: 'pens_and_writing', label: 'Pens & Writing' },
        { key: 'printers_and_ink', label: 'Printers & Ink' },
        { key: 'school_supplies', label: 'School Supplies' },
      ],
    },
  },
  {
    id: 'category_officeProducts',
    label: 'Office Products',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'office-products',
    queryParamNames: ['pub_subcatergory_officeProducts'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_stationery_and_office', label: 'All Stationery & Office' },
        { key: 'best_sellers', label: 'Best Sellers' },
        { key: 'arts_and_crafts', label: 'Arts & Crafts' },
        { key: 'calendars_and_personal_organisers', label: 'Calendars & Personal Organisers' },
        { key: 'office_furniture_and_lighting', label: 'Office Furniture & Lighting' },
        { key: 'office_electronics', label: 'Office Electronics' },
        { key: 'office_organisation', label: 'Office Organisation' },
        { key: 'office_supplies', label: 'Office Supplies' },
        { key: 'paper_products', label: 'Paper Products' },
        { key: 'pens_and_writing', label: 'Pens & Writing' },
        { key: 'printers_and_ink', label: 'Printers & Ink' },
        { key: 'school_supplies', label: 'School Supplies' },
      ],
    },
  },
  {
    id: 'category_petSupplies',
    label: 'Pet Supplies',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'pet-supplies',
    queryParamNames: ['pub_subcatergory_petSupplies'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_pet_supplies', label: 'All Pet Supplies' },
        { key: 'birds', label: 'Birds' },
        { key: 'cats', label: 'Cats' },
        { key: 'dogs', label: 'Dogs' },
        { key: 'fish_and_aquatic_pets', label: 'Fish & Aquatic Pets' },
        { key: 'small_animals', label: 'Small Animals' },
        { key: 'pet_profiles', label: 'Pet Profiles' },
        { key: 'subscribe_and_save', label: 'Subscribe & Save' },
      ],
    },
  },
  {
    id: 'category_sportsAndOutdoors',
    label: 'Sports & Outdoors',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'sports-and-outdoors',
    queryParamNames: ['pub_subcatergory_sportsAndOutdoors'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_sports_fitness_and_outdoors', label: 'All Sports, Fitness & Outdoors' },
        { key: 'exercise_and_fitness', label: 'Exercise & Fitness' },
        { key: 'camping_and_hiking', label: 'Camping & Hiking' },
        { key: 'cycling', label: 'Cycling' },
        { key: 'sports_tech', label: 'Sports Tech' },
        { key: 'fishing', label: 'Fishing' },
        { key: 'water_sports', label: 'Water Sports' },
        { key: 'golf', label: 'Golf' },
        { key: 'running', label: 'Running' },
        { key: 'sports_nutrition', label: 'Sports Nutrition' },
        { key: 'sports_and_outdoor_clothing', label: 'Sports & Outdoor Clothing' },
        { key: 'sports_and_outdoor_shoes', label: 'Sports & Outdoor Shoes' },
      ],
    },
  },
  {
    id: 'category_toysAndGames',
    label: 'Toys & Games',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'toys-and-games',
    queryParamNames: ['pub_subcatergory_toysAndGames'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'toys_and_games', label: 'Toys & Games' },
        { key: 'arts_and_crafts', label: 'Arts & Crafts' },
        { key: 'building_games', label: 'Building Games' },
        { key: 'outdoor_play', label: 'Outdoor Play' },
        { key: 'learning_games', label: 'Learning Games' },
        { key: 'board_games', label: 'Board Games' },
      ],
    },
  },
  {
    id: 'category_videoGames',
    label: 'Video Games',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'video-games',
    queryParamNames: ['pub_subcatergory_videoGames'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_video_games', label: 'All Video Games' },
        { key: 'video_games_best_sellers', label: 'Video Games Best Sellers' },
        { key: 'video_games_pre_orders', label: 'Video Games Pre-Orders' },
        { key: 'nintendo', label: 'Nintendo' },
        { key: 'playstation', label: 'Playstation' },
        { key: 'xbox', label: 'Xbox' },
        { key: 'consoles', label: 'Consoles' },
        { key: 'pc_games', label: 'PC Games' },
        { key: 'accessories', label: 'Accessories' },
        { key: 'twitch_prime', label: 'Twitch Prime' },
      ],
    },
  },
  {
    id: 'category_videoGames',
    label: 'Home Improvement',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'home-improvement',
    queryParamNames: ['pub_subcatergory_home_improvement'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_home_improvement_and_diy', label: 'All Home Improvement & DIY' },
        { key: 'power_and_hand_tools', label: 'Power & Hand Tools' },
        { key: 'electrical', label: 'Electrical' },
        { key: 'lighting', label: 'Lighting' },
        { key: 'hardware', label: 'Hardware' },
        { key: 'safety_and_security', label: 'Safety & Security' },
        { key: 'smart_home', label: 'Smart Home' },
      ],
    },
  },
  {
    id: 'category_beer_wine_and_spirits',
    label: 'Beer, Wine & Spirits',
    type: 'SelectSingleFilter',
    group: 'primary',
    parentId: 'wine-and-spirits',
    queryParamNames: ['pub_subcatergory_wine_and_spirits'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'all_beer_wine_and_spirits', label: 'All Beer, Wine & Spirits' },
        { key: 'beer', label: 'Beer' },
        { key: 'gin', label: 'Gin' },
        { key: 'rum', label: 'Rum' },
        { key: 'sparkling', label: 'Sparkling' },
        { key: 'spirits', label: 'Spirits' },
        { key: 'tequila', label: 'Tequila' },
        { key: 'vodka', label: 'Vodka' },
        { key: 'whisky', label: 'Whisky' },
        { key: 'wine', label: 'Wine' },
      ],
    },
  },
];

export const mergedCategoryOptions = filters.reduce((acc, category) => {
  if (category.config && category.config.options) {
    const filters = category.config.options.map(option => ({
      ...option,
      parentId: category?.parentId,
    }));
    acc.push(...filters);
  }
  return acc;
}, []);
