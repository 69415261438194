import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addSubscriber } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const addISubsriber = params => async (dispatch, getState, sdk) => {
  try {
    const { authenticatedData = {} } = (await addSubscriber(params)) || {};
    await dispatch(
      updateProfile({
        protectedData: {
          suiteData: authenticatedData,
        },
      })
    );
    return authenticatedData;
  } catch (error) {
    console.log(error, 'error while adding subscriber on signup');
  }
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
