import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { AspectRatioWrapper, AvatarSmall, NamedLink, ResponsiveImage } from '../../components';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';

import IconHeart from '../IconHeart/IconHeart';
import IconHeartFilled from '../IconHeartFilled/IconHeartFilled';

import css from './ListingCard.module.css';
import { selectCurrentUser } from '../../ducks/user.duck';
import { findOptionsForSelectFilter } from '../../util/search';
import { useConfiguration } from '../../context/configurationContext';
import { getWishlist } from '../../util/dataExtractors';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { mergedCategoryOptions } from '../../config/customConfig';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const dispatch = useDispatch();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing = () => {},
    showAuthorInfo = false,
  } = props;

  const config = useConfiguration();

  const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intl, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  };
  const currentUser = useSelector(selectCurrentUser);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, description, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const onUpdateCurrentUser = array => {
    const params = { wishlist: Array.from(new Set(array)) };
    return dispatch(
      updateProfile({
        publicData: { ...params },
      })
    );
  };

  const wishlist = getWishlist(currentUser);

  const subcategory = mergedCategoryOptions?.find(m => m.key === publicData?.subCategory);

  const listingFields = config.listing.listingFields;
  const categories = listingFields.find(l => l.key === 'category')?.enumOptions;
  const category = categories.find(c => c.option === publicData?.category)

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <div className={css.head}>
        <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
          <div
            className={css.favorite}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              const index = wishlist && wishlist.findIndex(b => b == id);
              if (id) {
                if (index > -1) {
                  wishlist && wishlist.splice(index, 1);
                  const removedwishlist = Array.from(new Set(wishlist));
                  onUpdateCurrentUser(removedwishlist);
                } else {
                  wishlist.push(id);
                  onUpdateCurrentUser(Array.from(new Set(wishlist)));
                }
              }
            }}
          >
            {wishlist && wishlist.findIndex(e => e == id) > -1 ? (
              <IconHeartFilled className={css.iconDislike} />
            ) : (
              <IconHeart className={css.iconlike} />
            )}
          </div>
        </div>
      </AspectRatioWrapper>

      <div className={css.info}>
        {/* <div className={css.mainInfo}>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <AvatarSmall user={author} />
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div> */}
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <span className={css.label}>{category?.label}</span>
        <span className={classNames(css.label, css.subCategory)}>{subcategory?.label}</span>
        {/* {description && (
          <div className={css.description}>{description ? description.slice(0, 140) : ''}</div>
        )} */}
        <div className={css.buttons}>
          {/* <button className={css.addCartBtn}>Add to cart</button> */}
          <button className={css.buyBtn}>Buy now</button>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
